import React from 'react';

import styled from 'styled-components';

import theme from 'config/theme';
import { BREAKPOINT_TABLET_PX } from 'constants/breakPoints';

import routes from 'config/routes';
import useRouter from 'hooks/useRouter';
import { Button } from 'lib/Button';
import ListingImageUrl from 'assets/images/homepage/listing.png';
import ConciergeImageUrl from 'assets/images/homepage/concierge.png';
import ChatImageUrl from 'assets/images/homepage/chat.png';
import PricingImageUrl from 'assets/images/homepage/pricing.png';

const { colors, fontWeights, fontSizes } = theme;

const HowItWorks = () => {
	const [goTo] = useRouter();

	return (
		<Container>
			<Title>How it works for Landlords</Title>
			<ItemsContainer>
				<Item>
					<ItemImage src={ListingImageUrl} />
					<ItemTitle>Create a free listing</ItemTitle>
					<ItemText>
						It's completely free to list on DigsConnect. Click on "create a listing" and follow the
						prompts to create your property listing in 10 minutes.
					</ItemText>
				</Item>
				<Item>
					<ItemImage src={ConciergeImageUrl} />
					<ItemTitle>Receive enquiries and a first message from the tenant</ItemTitle>
					<ItemText>
						Once you have finished creating your property listing, the listing will go into
						moderation. Within 24 hours your new booking concierge will give you a call.
					</ItemText>
				</Item>
				<Item>
					<ItemImage src={ChatImageUrl} />
					<ItemTitle>Discuss leasing arrangements, share documents and arrange a viewing</ItemTitle>
					<ItemText>
						When applicants send you booking enquiries on DigsConnect, you can chat with them on the
						‘Inbox’. You have 48 hours to reply to a booking request.
					</ItemText>
				</Item>
				<Item>
					<ItemImage src={PricingImageUrl} />
					<ItemTitle>
						Sign the lease, and we'll check in with the tenant to tie up any loose ends
					</ItemTitle>
					<ItemText>
						Upon confirmation of tenancy the landlord will be charged a fixed finder's fee of R850
						for leases shorter than 6 months, and R1550 for leases of 6 months or longer per tenant.
						Rates are exclusive of VAT.
					</ItemText>
				</Item>
			</ItemsContainer>
			<ButtonContainer>
				<Button
					isPink
					link={routes.create_listing}
					onClick={e => {
						e.stopPropagation();
						e.preventDefault();
						goTo(routes.create_listing);
					}}
				>
					+ Create your free listing
				</Button>
			</ButtonContainer>
		</Container>
	);
};

export default HowItWorks;

const Title = styled.h2`
	font-weight: ${fontWeights.bold};
	font-size: ${fontSizes.xxlarge};
	margin-bottom: 32px;
	text-align: center;

	@media (max-width: 500px) {
		font-size: ${fontSizes.xlarge};
		width: 100%;
	}
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: ${colors.darkTurquoiseFaded};
	padding: 48px 16px;
	padding-bottom: 24px;
`;

const ItemsContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		flex-direction: column;
	}
`;

const Item = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	max-width: 240px;
	margin: 0 24px;
`;

const ItemTitle = styled.div`
	color: ${colors.darkBlue};
	text-align: center;
	font-weight: 600;
	margin-bottom: 16px;
`;

const ItemText = styled.div`
	color: ${colors.darkBlue};
	font-size: 14px;
	text-align: center;
	margin-bottom: 24px;
`;

const ItemImage = styled.img`
	height: 96px;
	margin-bottom: 16px;
`;

const ButtonContainer = styled.div`
	margin-bottom: 24px;
	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		width: 80%;
	}
`;
