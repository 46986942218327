import { Faq } from './types';

export const faqData: Faq[] = [
	{
		question: 'What do you charge and do I pay monthly or annually? ',
		answer: `Upon confirmation of tenancy the landlord will be charged a fixed finder's fee of R850 for leases shorter than 6 months, and R1550 for leases of 6 months or longer per tenant. Rates are exclusive of VAT.`,
	},
	{
		question: 'What checks do you do on tenants? ',
		answer: `Every enquiry you receive through DigsConnect will include personal, educational and financial information provided by the tenant. In addition you will receive verified contact information for the prospective tenant.`,
	},
	{
		question: 'How do I access my incoming enquiries?',
		answer: `All your enquiries will be available in your DigsConnect <span><a href="https://app.digsconnect.com/#/inbox">inbox</a></span>. Here you can chat with the tenants, and access their personal, educational and financial information and request any other information or documentation required. In addition you will receive an email and sms every time you receive an enquiry or when a tenant replies to your latest message.`,
	},
	{
		question: 'Who do I contact to help me with issues I am experiencing with my profile?',
		answer: `We have a dedicated landlord success lead who is available during working hours: <span><a href="mailto:landlords@digsconnect.com">landlords@digsconnect.com</a></span> `,
	},
	{
		question: 'How do I get my listing to be seen first?',
		answer: `Here is a link to the <span><a href="https://www.digsconnect.com/landlord-resources">landlord resources</a></span> page which explains our listing ranking process. `,
	},
	{
		question: 'Am I locked into a contract with DigsConnect?',
		answer: `Nope, while you will be subject to our standard Terms and Conditions you are not locked into a contract or any exclusivity agreements with us.`,
	},
	{
		question: 'Can I chat to someone about my listing?',
		answer: `We are always a phonecall or email away. Get in touch with us if you require assistance or have questions about your listing on DigsConnect. You can use our <span><a href="https://www.digsconnect.com/contact-us/"> contact us page</a></span>`,
	},
	{
		question: 'How do I monitor my performance?',
		answer: `Once you have created your listing you will have access to your own landlord dashboard where you can monitor your performance. `,
	},
	{
		question: 'Do I get a refund if the tenant moves out early?',
		answer: `Typically, no. Our service is to get you a tenant that moves into your property. We suggest this is included in your agreement with the tenant, to protect you from being out-of-pocket. You can read our Terms and Conditions <span><a href="https://www.digsconnect.com/terms-and-conditions">here</a></span>. `,
	},
];
