import React from 'react';

import styled from 'styled-components';
import PricingBlock1ImageUrl from 'assets/images/pricing/types.png';
import PricingBlock3ImageUrl from 'assets/images/pricing/pricing-new.png';
import PricingBlock4ImageUrl from 'assets/images/pricing/chat.png';
import theme from 'config/theme';

import { ReactComponent as SectionBreak } from 'assets/images/landing/SectionBreak.svg';
import { BREAKPOINT_TABLET_PX, BREAKPOINT_MOBILE_PX } from 'constants/breakPoints';
import { Button } from 'lib/Button';
import routes from 'config/routes';

const { colors } = theme;

interface PricingBlocksSectionProps {
	background?: string;
}

const PricingBlocksSection = ({ background }: PricingBlocksSectionProps) => (
	<Container style={{ background: background || colors.white }}>
		<SectionTitle>{'How Pricing Works'}</SectionTitle>
		<ItemsContainer>
			<Item>
				<img src={PricingBlock1ImageUrl} />
				<ItemText>
					<ItemTitle>Listing is free</ItemTitle>
					<Text>
						Get all your properties listed on our secure platform. List as many properties as you’d
						like, at no extra cost.
					</Text>
				</ItemText>
			</Item>
			<Item>
				<PricingImageContainer>
					<PricingImage src={PricingBlock3ImageUrl} />
				</PricingImageContainer>
				<ItemText>
					<ItemTitle>Pay on success</ItemTitle>
					<Text>
						If you find a tenant on DigsConnect, your booking concierge will invoice you for the
						one-off success fee.
					</Text>
				</ItemText>
			</Item>
			<Item>
				<img src={PricingBlock4ImageUrl} />
				<ItemText>
					<ItemTitle>Invoice and fee</ItemTitle>
					<Text>
						Upon confirmation of tenancy the landlord will be charged a fixed finder's fee of R850
						for leases shorter than 6 months, and R1550 for leases of 6 months or longer per tenant.
						Rates are exclusive of VAT.
					</Text>
				</ItemText>
			</Item>
		</ItemsContainer>
		<ButtonContainer>
			<Button isOutline link={routes.pricing}>
				View pricing page
			</Button>
		</ButtonContainer>
		<SectionBreakContainer>
			<SectionBreak />
		</SectionBreakContainer>
	</Container>
);

export default PricingBlocksSection;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: ${colors.white};
	gap: 42px;
	margin-top: 40px;
`;

const PricingImageContainer = styled.div`
	height: 150px;
	min-width: 240px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const PricingImage = styled.img`
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
	border-radius: 16px;
`;

const ButtonContainer = styled.div`
	margin-bottom: 24px;
	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		display: none;
	}
`;

const SectionBreakContainer = styled.div`
	display: flex;
	width: 100%;

	@media (max-width: ${BREAKPOINT_MOBILE_PX}px) {
		display: none;
	}
`;

const SectionTitle = styled.div`
	font-weight: 700;
	font-size: 32px;
	line-height: 110%;
	text-align: right;
	color: ${colors.darkBlue};
`;

const ItemsContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: center;
	margin-top: 16px;
	gap: 42px;
	width: 80%;

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		flex-direction: column;
		align-items: center;
	}
`;

const Item = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	max-width: 240px;
	margin: 0 24px;
	gap: 30px;
`;

const ItemTitle = styled.div`
	color: ${colors.darkBlue};
	font-weight: 600;
	font-size: 16px;
	margin-bottom: 16px;
`;

const ItemText = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 10px;
`;
const Text = styled.div`
	color: ${colors.darkBlue};
	font-size: 14px;
	text-align: center;
	margin-bottom: 24px;
`;

const BoldItemText = styled.span`
	font-weight: bold;
`;
